<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="区域名称" prop="areaName">-->
<!--                <a-input v-model="queryParam.areaName" placeholder="请输入区域名称" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="Name" prop="fullName">-->
<!--                <a-input v-model="queryParam.fullName" placeholder="" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <template v-if="advanced">-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="上级区域ID" prop="parentId">-->
<!--                  <a-input v-model="queryParam.parentId" placeholder="请输入上级区域ID" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="区域等级" prop="level">-->
<!--                  <a-input v-model="queryParam.level" placeholder="请输入区域等级" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="中心点的经纬度" prop="centerLatLon">-->
<!--                  <a-input v-model="queryParam.centerLatLon" placeholder="请输入中心点的经纬度" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--            </template>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
<!--                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>-->
<!--                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>-->
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd({
        level:1,
        parentId:0 ,
        fullName: null,
        areaName:null
        })" v-hasPermi="['area:area:add']">
          <a-icon type="plus" />Add
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['area:area:edit']">-->
<!--          <a-icon type="edit" />Modify-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['area:area:remove']">-->
<!--          <a-icon type="delete" />Delete-->
<!--        </a-button>-->
        <a-button type="dashed"  @click="handleRefreshCache">
          <a-icon type="redo" />Refresh
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['area:area:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="handleRefreshCache" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="handleRefreshCache"
      />
      <!-- 数据展示 438762775905460224 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="areaId"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical"/>-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />Modify
          </a>

           <a-divider type="vertical" v-if="record.level != 3" />
          <a @click="$refs.createForm.handleAdd({
            level:record.level == 1 ? 2 : 3,
            parentId:record.areaId,
            fullName: null,
            areaName:null
          }, undefined)" v-if="record.level != 3">
            <a-icon type="plus" />Add Subordinate
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['area:area:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['area:area:remove']">-->
<!--            <a-icon type="delete" />Delete-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
<!--      <a-pagination-->
<!--        class="ant-table-pagination"-->
<!--        show-size-changer-->
<!--        show-quick-jumper-->
<!--        :current="queryParam.pageNum"-->
<!--        :total="total"-->
<!--        :page-size="queryParam.pageSize"-->
<!--        :showTotal="total => `共 ${total} 条`"-->
<!--        @showSizeChange="onShowSizeChange"-->
<!--        @change="changeSize"-->
<!--      />-->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageArea, listArea, delArea, treeArea, refreshArea} from '@/api/area/area'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Area',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        areaName: null,
        fullName: null,
        parentId: null,
        level: null,
        centerLatLon: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'Area ID',
          dataIndex: 'areaId',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '区域名称',
        //   dataIndex: 'areaName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Name',
          dataIndex: 'fullName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '上级区域ID',
        //   dataIndex: 'parentId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'level',
          dataIndex: 'level',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '中心点的经纬度',
        //   dataIndex: 'centerLatLon',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleRefreshCache() {
      refreshArea().then(res => {
        if(res.success) {
          this.getList();
        }
      })
    },
    /** 查询省市区地区信息列表 */
    getList () {
      this.loading = true
     treeArea(this.queryParam).then(response => {
        this.list = response.data;

        // this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        areaName: undefined,
        fullName: undefined,
        parentId: undefined,
        level: undefined,
        centerLatLon: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.areaId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const areaIds = row.areaId || this.ids
      if(row.areaId){
        this.ids.push(row.areaId)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + areaIds + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push(id))
          return delArea(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('area/area/export', {
            ...that.queryParam
          }, `省市区地区信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
