var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-header-wrapper',[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"table-page-search-wrapper"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-row',{attrs:{"gutter":48}},[_c('a-col',{attrs:{"md":!_vm.advanced && 8 || 24,"sm":24}},[_c('span',{staticClass:"table-page-search-submitButtons",style:(_vm.advanced && { float: 'right', overflow: 'hidden' } || {})})])],1)],1)],1),_c('div',{staticClass:"table-operations"},[_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['area:area:add']),expression:"['area:area:add']"}],attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.createForm.handleAdd({
        level:1,
        parentId:0 ,
        fullName: null,
        areaName:null
        })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("Add ")],1),_c('a-button',{attrs:{"type":"dashed"},on:{"click":_vm.handleRefreshCache}},[_c('a-icon',{attrs:{"type":"redo"}}),_vm._v("Refresh ")],1)],1),_c('create-form',{ref:"createForm",on:{"ok":_vm.handleRefreshCache}}),_c('a-table',{attrs:{"loading":_vm.loading,"size":_vm.tableSize,"rowKey":"areaId","columns":_vm.columns,"data-source":_vm.list,"pagination":false,"bordered":_vm.tableBordered},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.$refs.createForm.handleUpdate(record, undefined)}}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v("Modify ")],1),(record.level != 3)?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(record.level != 3)?_c('a',{on:{"click":function($event){return _vm.$refs.createForm.handleAdd({
            level:record.level == 1 ? 2 : 3,
            parentId:record.areaId,
            fullName: null,
            areaName:null
          }, undefined)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("Add Subordinate ")],1):_vm._e()],1)}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }